<template>
    <div class="home">
        <!-- 内容 -->
        <div class="rulesReadcontentBox">
            <div v-html="content"></div>
        </div>
    </div>
</template>
  
<script>
import { rulesRead } from "../../utils/api.js";

export default {
    data() {
        return {
            content:''
        };
    },
    mounted() {
        this.getRulesRead();
    },
    methods: {
        //  请求轮播图
        getRulesRead() {
            rulesRead({ condition: { type_value: this.$route.query.type } }).then((res) => {
                this.content = res.data.content
                console.log(res);
            });
        },
    },
};
</script>
  
<style lang="less" scoped>

.rulesReadcontentBox{
    /deep/ img{
        width: 100%;
    }
}
</style>